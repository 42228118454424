import '@deromeab/components/dist/esm/index.css';
import styles from './style.module.css';

import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import {
  Button,
  ButtonType,
  CheckboxItemProps,
  Checkboxes,
  Spinner,
} from '@deromeab/components';
import { NotificationFlags } from '@deromeab/notifications-common';

import { getCustomerContactSetting } from '../../../queries/get-customer-contact-setting';
import { setCustomerContactSetting } from '../../../queries/set-customer-contact-setting';

const options = [
  {
    value: NotificationFlags.OrderConfirmation,
    name: 'Orderbekräftelse',
    checked: false,
    disabled: false,
  },
  {
    value: NotificationFlags.DeliveryPlanned,
    name: 'Leverans planerad',
    checked: false,
    disabled: false,
  },
  {
    value: NotificationFlags.DriveTo,
    name: 'Leverans på väg',
    checked: false,
    disabled: false,
  },
  {
    value: NotificationFlags.Completed,
    name: 'Levererad',
    checked: false,
    disabled: false,
  },
  {
    value: NotificationFlags.ReayForPickup,
    name: 'Redo för upphämtning',
    checked: false,
    disabled: false,
  },
];

interface Props {
  customerId: string;
}

export const NotificationSettings = ({ customerId: phone }: Props) => {
  const [isShowModal, setShowModal] = useState<boolean>(false);
  const [checkboxes, setCheckboxes] = useState<CheckboxItemProps[]>(options);

  // Load customer notification setting data
  const { data, isLoading } = getCustomerContactSetting(phone);

  useEffect(() => {
    if (!isLoading && data) {
      const notificationSettingsData =
        Number(data.getCustomerContactSetting?.setting) || 0;

      setCheckboxes(
        checkboxes.map((checkbox, index) => {
          const bitwiseValue = 1 << index;

          return {
            ...checkbox,
            checked: (notificationSettingsData & bitwiseValue) !== 0,
          };
        }),
      );
    }
  }, [data, isLoading]);

  const notificationSettingsMutation = useMutation(setCustomerContactSetting);

  useEffect(() => {
    if (!notificationSettingsMutation.isLoading) {
      setShowModal(false);
    }
  }, [notificationSettingsMutation.isLoading]);

  const saveNotificationSettings = (checkboxes: CheckboxItemProps[]) => {
    const bitwiseValue = checkboxes.reduce(
      (acc, setting) => (setting.checked ? acc + Number(setting.value) : acc),
      0,
    );

    if (!phone) return;

    notificationSettingsMutation.mutate({
      customerId: phone,
      setting: bitwiseValue,
    });

    setCheckboxes(checkboxes);
  };

  return (
    <div className={styles.notificationSettingsWrapper}>
      <div className={styles.settingsWrapper}>
        <Button
          onClick={() => {
            setShowModal(!isShowModal);
          }}
          classNames={styles.button}
          text="Justera SMS - avisering"
          disabled={false}
          buttonType={ButtonType.Primary}
        />
      </div>

      {isShowModal && (
        <div className={styles.checkboxesModalContainer}>
          <NotificationSettingsModal
            checkboxes={checkboxes}
            onSubmit={saveNotificationSettings}
            buttonDisabled={notificationSettingsMutation.isLoading}
          />

          {(isLoading || notificationSettingsMutation.isLoading) && (
            <div
              className={[
                styles.waitLoaderOverlay,
                notificationSettingsMutation.isLoading
                  ? styles.waitLoaderOpac
                  : '',
                isLoading ? styles.waitLoaderBlur : '',
              ].join(' ')}
            >
              <Spinner size={'medium'} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const NotificationSettingsModal = ({
  checkboxes,
  onSubmit,
  buttonDisabled,
}: {
  checkboxes: CheckboxItemProps[];
  onSubmit: (checkboxes: CheckboxItemProps[]) => void;
  buttonDisabled: boolean;
}) => {
  return (
    <Checkboxes
      header={'Välj meddelanden:'}
      checkboxes={checkboxes}
      buttonText={'Spara'}
      buttonDisabled={buttonDisabled}
      onSubmit={onSubmit}
    />
  );
};
